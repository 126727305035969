<template>
    <div>
        <strong>Asset Upload</strong>
        <br>
        <div class="form-group">
            <label>
                <input type="radio" v-model="state.fileTypeID" :value="this.$cdiVars.FILE_TYPE_ASSET_MAINTENANCE_DOCUMENT"/>
                Maintenance Document
            </label>
        </div>
        <div class="form-group">
            <label>File title</label>
            <input type="text" v-model="state.fileName" class="form-control" />
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";

    export default {
        name: "AssetMaintenanceDocument",
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_ASSET_MAINTENANCE_DOCUMENT);
        }
    }
</script>

<style scoped>

</style>